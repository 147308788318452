import React, { useEffect } from "react"
import { useResources } from "../common/hooks"
import {
  ResourceFilters,
  ResourcePager,
  SearchBar,
  Layout,
  CenteredLayout,
  Loader,
} from "@components"
import { getResources } from "@services"
import { sortByDate } from "@utilities"
import { RESOURCE_FIELDS, SORT_DIRECTION } from "../common/constants"

const CommunityResources = () => {
  const {
    resourcesStates,
    handleInitialState,
    handleFilters,
    handleSearch
  } = useResources();

  useEffect(() => {
    const loadResources = async () => {
      const resources = await getResources()
      const sortedResources = sortByDate(
        resources,
        RESOURCE_FIELDS.lastModifiedDateTime,
        SORT_DIRECTION.desc
      )
      handleInitialState(sortedResources)
    }
    loadResources()
  }, [])

  return (
    <Layout>
      <CenteredLayout className="bg-nwh-light-gray">
        <SearchBar onSearch={handleSearch} className="mb-6" />
        <ResourceFilters allResources={resourcesStates.allResources} filterKeys={resourcesStates.filterKeys} onChange={handleFilters} />
      </CenteredLayout>
      <CenteredLayout>
        <div className="text-nwh-dark-blue py-6 text-center">
          For more information, please contact Community Health at{" "}
          <a
            className="text-nwh-dark-blue underline"
            href="mailto:communityhealth@northwell.edu"
          >
            communityhealth@northwell.edu
          </a>
        </div>
        {!resourcesStates.loading && <ResourcePager data={resourcesStates.results} />}
        {resourcesStates.loading && <Loader label="loading..." />}
      </CenteredLayout>
    </Layout>
  )
}

export default CommunityResources